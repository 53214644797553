
import React from 'react';
import Navbar from './component/Navbar/Navbar';
import RBanner from './component/Banner/RippleBanner';

import TypeBanner from './component/Banner/TypeBanner';
import About from './component/About';
import Service from './component/Service';
import Portfolio from './component/Portfolio/Portfolio'; 
import Contact from './component/Contact';
import Skill from './component/Skill/Skill';
import Footer from './component/Footer';
import jhonData from './component/jhonData';


const Home2 = () => {
    return <div className="body_wrapper">
        <Navbar mContainer="custome_container" mainlogo="logo2.png" stickylogo="logo.png"/>
        <RBanner jhonData={jhonData} bClass="banner_shap_three" imag="man.jpg" textLeft="text-left"/>

        {/* <TypeBanner jhonData={jhonData}/> */}
        <About aClass="about_area" jhonData={jhonData}/>
        <Service wClass="work_area" jhonData={jhonData}/>
        <Portfolio/>
        <Skill/>
        <Contact jhonData={jhonData}/>
        <Footer jhonData={jhonData}/>
  </div>;
};

export default Home2;

