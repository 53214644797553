import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

class About extends Component {
  render() {
    let jhonData = this.props.jhonData;
    var { aClass } = this.props;
    return (
      <section className={`${aClass}`} id="about">
        <div className="container">
          <div className="row">

            <div className="col-lg-8 d-flex align-items-center">
              <Fade direction="up" triggerOnce="true" damping={0.1}>
                <div className="about_content">
                  <h2 className="t_color">{jhonData.aboutme}</h2>
                  <h6>{jhonData.role}</h6>
                  <p>{jhonData.aboutdetails}</p>
                </div>
              </Fade>
            </div>
            <div className="col-lg-4">
              <div className="about_img">
                <Fade direction="right" duration={1500}>
                  <img src={require("../image/apporoach_man_img.jpg")} alt="" height={500} />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
