import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
/*-----pages-----*/
import Home2 from "./Home2";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Home2 />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
