import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-scroll";

import Typer from "./Typer";

class TypeBanner extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section id="home">
        <Parallax
          bgImage={require("../../image/intro-bg.jpg")}
          strength={0}
          className="banner_area">
          <div className="container">
            <div className="banner_content">
              <Fade direction="up">
                <h5 className="wow fadeInUp animated">Hey</h5>
              </Fade>
              <Fade direction="up" duration={1500}>
                <h2 className="wow fadeInLeft animated">I’m Riadh JOUINI</h2>
              </Fade>
              <Fade direction="up" duration={2000}>
                {" "}
                <Typer
                  heading={"I am a"}
                  dataText={["Best-selling author", "public speaker"]}
                />
              </Fade>
              <ul className="list_style social_icon">
                {jhonData.socialLinks &&
                  jhonData.socialLinks.map((item) => {
                    return (
                      <li key={item.name}>
                        <a href={item.url}>
                          <i className={item.className}></i>
                        </a>
                      </li>
                    );
                  })}
              </ul>
              <div style={{ marginTop: "40px" }}>
                <Link
                  className="btn send_btn theme_btn2"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}>
                  Book an appointment
                </Link>
              </div>
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}

export default TypeBanner;
