const jhonData = {
  name: "Riadh Jouini.",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "IT knowledge Evangelist and public speaker",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "Facebook",
      url: "https://www.facebook.com/riadh.jouini.10",
      className: "social_facebook",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/riadh-jouini-359b1baa/",
      className: "social_linkedin",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/riadh.jouini.10/",
      className: "social_instagram",
    },
  ],
  aboutme: "Tech talks with Riadh Jouini",
  aboutdetails:
    "Welcome to the hub where technology, strategy, and eloquence intersect. I am Riadh JOUINI, an IT author and a fervent advocate for strategic thinking. My services extend beyond the confines of boardrooms and design studios. As a public speaker, I am passionate about sharing the insights gleaned from my years in IT architecture with diverse audiences. Whether addressing industry professionals at high-profile conferences, engaging with budding minds in university lecture halls, or leading workshops in corporate settings, my goal remains the same: to enlighten, inspire, and stimulate intellectual curiosity    ",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Public Speaking",
      sDetails: "As a public speaker, I engage audiences on a variety of technical and leadership topics, delivering key insights with clarity and enthusiasm. My experience allows me to connect with diverse groups, inspiring and informing with each speech."
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Conferences and Tech Talks",
      sDetails: "I regularly contribute to conferences and tech talks, sharing expertise in IT architecture and industry trends. These sessions are crafted to provide valuable, actionable knowledge to professionals and enthusiasts alike."
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "IT architecture advisor",
      sDetails: "I provide advisory services to organizations, guiding them through complex technical landscapes. My role involves strategic planning, technology selection, and system optimization."
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: " Guest lecturing ",
      sDetails: "As a guest lecturer, I bring real-world experience to academic settings, offering students insights into cutting-edge technology and industry best practices. My lectures blend theoretical knowledge with practical applications."
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Webinars and Virtual Talks",
      sDetails: " In my webinars and virtual talks, I address current topics in technology and leadership, reaching a global audience. These online events are interactive and tailored to meet the evolving needs of remote learning and professional development."
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "Leadership content creation",
      sDetails: " I create compelling content on leadership in the tech industry, drawing on my experiences to inspire and guide upcoming leaders. My content spans articles, blogs, and multimedia, all aimed at fostering leadership skills in the tech sector."
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Z105 - Tan Thinh, Thai Nguyen - Viet Nam",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +1.900.3456.789",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: yourname@somemail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: http://example.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Get in touch",
};

export default jhonData;
