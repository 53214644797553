import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import CounterItem from "../Skill/CounterItem";
import { Fade } from "react-awesome-reveal";
import Sectiontitle from '../../component/Banner/Sectiontitle';
class Skill extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = { count: 0 };
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: "easeOutCubic",
        update() {
          el.innerText = data.count.toLocaleString();
        },
      });
    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll("[data-countup]");

    els.forEach(makeCountup);
  }
  render() {
    var { cClass } = this.props;
    return (
      <section className={`project_count_area ${cClass}`}>
        <div className="container">
        <div className="container">
                <Sectiontitle Title="Recent key figures" />
              
            </div>
          <Fade direction="up" cascade triggerOnce="true">
            <div className="row">
              <CounterItem
                CText="100"
                pdescription="Sold worldwide"
                icon="presentation"
              />
              <CounterItem
                CText="200"
                pdescription="readers"
                icon="happy"
              />
              <CounterItem
                CText="2"
                pdescription="books written "
                icon="quote"
              />
              <CounterItem CText="10" pdescription="virtual events" icon="mic" />
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}
export default Skill;
