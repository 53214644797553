import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";
import Form from "./Form";

class Contact extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section className="contact-area" id="contact">
        <div className="container">
          <div className="row row-reverse">
   
            <div className="col-md-12">
              <Fade direction="Right" triggerOnce duration={800}>
                <div className="input_form">
                  <h4>{jhonData.contacttitle}</h4>
                  <h3>Connect with Riadh JOUINI for Collaborations and Inquiries</h3>
                  <br></br>
                  <Form />
                  <div id="success">Your message succesfully sent!</div>
                  <div id="error">
                    Opps! There is something wrong. Please try again
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;
