import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";
import WaterWave from "react-water-wave";
import { Link } from "react-scroll";

class Banner extends Component {
  render() {
    let jhonData = this.props.jhonData;
    let { bClass, textLeft, imag } = this.props;
    return (
      <section id="home">
        <WaterWave
          strength={500}
          className={`banner_area ${bClass}`}
          style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
          imageUrl={require("../../image/" + imag)}>
          {({ getRootProps }) => (
            <div className="container">
              <div className={`banner_content ${textLeft}`}>
                <Fade direction="up">
                  <h5>Hey</h5>
                </Fade>
                <Fade direction="up" duration={1500}>
                  <h2 className="wow fadeInLeft animated">
                    I am {jhonData.name}
                  </h2>
                </Fade>
                <Fade direction="up" duration={2200}>
                  <h4 className="wow fadeInUp anoimated">
                    {" "}
                    Innovative Tech Leader, Author, Inspirer{" "}
                  </h4>
                </Fade>
                <ul className="list_style social_icon">
                  {jhonData.socialLinks &&
                    jhonData.socialLinks.map((item) => {
                      return (
                        <li key={item.name}>
                          <a href={item.url}>
                            <i className={item.className}></i>
                          </a>
                        </li>
                      );
                    })}
                </ul>
                <div style={{ marginTop: "40px" }}>
                  <Link
                    className="btn send_btn theme_btn2"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}>
                    Book an appointment
                  </Link>
                </div>
              </div>
            </div>
          )}
        </WaterWave>
      </section>
    );
  }
}

export default Banner;
